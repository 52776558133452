export const backends = [
  // { name: 'Travel', url: 'https://test-backend.palmatravel.tech' },
  { name: 'Finance', url: 'https://finance-backend.palmaseguros.tech' },
  { name: 'Quote', url: 'https://api.palmaseguros.tech' },
  // { name: 'Tracker', url: 'https://tracker-backend-admin.palmaseguros.tech' },
  { name: 'CRM', url: 'https://crm-backend.palmaseguros.tech' },
  { name: 'Email', url: 'https://emails-backend.palmaseguros.tech' }
];

// export const backends = [
  // { name: 'CRM', url: 'http://localhost:8001' },
  // { name: 'Email', url: 'http://localhost:8002' },
  // { name: 'Finance', url: 'http://localhost:8000' },
  // { name: 'Quote', url: 'http://localhost:8004' },
  // { name: 'Tracker', url: 'http://localhost:8005' },
  // { name: 'Travel', url: 'http://localhost:8006' },
// ];

export const isAuthenticated = () => {
  const authenticatedApps = getAuthenticatedApps();
  return authenticatedApps.length !== 0;
};

export const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};domain=palmaseguros.tech;path=/`;
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const logout = async () => {
  for (const backend of backends) {
    const token = getCookie(`Palma${backend.name}Token`);
    console.log(`Palma${backend.name}Token`, token);
    if (token !== "") {
      document.cookie = `Palma${backend.name}Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=palmaseguros.tech; path=/;`
      document.cookie = `Palma${backend.name}User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=palmaseguros.tech; path=/;`
    }
  }
}

export const getAuthenticatedApps = () => {
  const authenticatedApps = [];
  for (const backend of backends) {
    const token = getCookie(`Palma${backend.name}Token`);
    if (token !== "") {
      authenticatedApps.push(backend.name);
    }
  }
  return authenticatedApps;
};